import { BaseClient } from "./base";
import { ClientConfig, Deserializer, HttpClient } from "./types";

export class Client implements HttpClient {
  #client: BaseClient;
  constructor(private readonly config: ClientConfig = {}) {
    this.#client = new BaseClient(config);
  }
  setHeader(key: string, value: string) {
    this.#client.setHeader(key, value);
  }
  async get<T>(path: string, d?: Deserializer<T>) {
    const response = await this.#client.get(path);
    return this.handleResponse(response, d);
  }

  async post<T, R extends object = object>(path: string, body: R, d?: Deserializer<T>) {
    const response = await this.#client.post(path, body);
    return this.handleResponse(response, d);
  }

  async put<T, R extends object = object>(path: string, body: R, d?: Deserializer<T>) {
    const response = await this.#client.put(path, body);
    return this.handleResponse(response, d);
  }

  async delete<T>(path: string, d?: Deserializer<T>) {
    const response = await this.#client.delete(path);
    return this.handleResponse(response, d);
  }

  private async handleResponse<T>(response: Response, d?: Deserializer<T>) {
    if (!response.ok) {
      console.warn(
        `Error while making request to ${response.url}. Status: ${response.status}. Response: ${await response
          .clone()
          .text()}`,
      );
      throw response;
    }
    if (d) return d.parse(await response.json());
    return response.json() as unknown as T;
  }
}
