import { ClientConfig } from "./types";
import fetch from "cross-fetch";

/**
 * A simple client that allows you to make requests to a server.
 * This won't attempt to parse the response, it will just return the response object.
 */
export class BaseClient {
  #headers: Record<string, string> = {};
  constructor(private readonly config: ClientConfig = {}) {
    this.#headers = config.headers ?? {};
  }
  setHeader(key: string, value: string) {
    this.#headers[key] = value;
  }

  async get(path: string) {
    const url = `${this.config.baseUrl ?? ""}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...this.#headers,
      },
    });
    return response;
  }

  async post<R extends object = object>(path: string, body: R) {
    const url = `${this.config.baseUrl ?? ""}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.#headers,
      },
      body: JSON.stringify(body),
    });
    return response;
  }

  async put<R extends object = object>(path: string, body: R) {
    const url = `${this.config.baseUrl ?? ""}${path}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...this.#headers,
      },
      body: JSON.stringify(body),
    });
    return response;
  }

  async delete(path: string) {
    const url = `${this.config.baseUrl ?? ""}${path}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...this.#headers,
      },
    });
    return response;
  }
}
